import Modal from "react-bootstrap/Modal";
import ThemeButton from "../theme/ThemeButton";
import styles from "./tipAmountModal.module.scss";
import { useLocation } from "react-router-dom";

interface Iprops {
  handleClose: () => void;
  show: boolean;
  title?: string;
  tipItems: TipItem[];
}
interface TipItem {
  label: string;
  value: string | number;
}

const TipAmountModal = ({
  handleClose,
  show,
  title,
  tipItems,
  children,
}: Iprops & { children?: React.ReactNode }) => {
  const location = useLocation();
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      className={
        location.pathname == "/reporting" ? styles.tipperModal : styles.tipModal
      }
      // style={{
      //   width: location.pathname == "/reporting" ? "440px" : "",
      // }}
    >
      <Modal.Header className={styles.heading}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          padding: 0,
        }}
      >
        <ul className={styles.TipAmount}>
          {tipItems?.map((item, index) => (
            <li
              key={index}
              style={{
                textAlign: "center",
              }}
            >
              <p>{item.label}</p>
              <h4
                style={{
                  wordBreak: "break-word",
                  fontSize: location.pathname == "/reporting" ? "14px" : "",
                }}
              >
                {item.value}
              </h4>
            </li>
          ))}
        </ul>
      </Modal.Body>
      <Modal.Footer className={styles.FooterModal}>{children}</Modal.Footer>
    </Modal>
  );
};

export default TipAmountModal;
