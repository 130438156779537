import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  notifyBugsnagError,
  showToast,
} from "../../../../common/utils/Functions";
import useFetch from "../../../../hooks/useFetch";
import { AddEditModalProps } from "../../../../types/modal/addEditModal";
import { propertyEmployeeSchema } from "../../../../validations/propertySchema";
import ModalLayout from "../../../layouts/ModalLayout";
import InputField from "../../../theme/InputField";
import PhoneIntputField from "../../../theme/PhoneIntputField";
import ReactSelectField from "../../../theme/ReactSelectField";
import ThemeButton from "../../../theme/ThemeButton";
import { propertyEmployees } from "../initialValues";
import ListEmployess from "./ListEmployess";

interface props {
  property_uuid: string;
  isEditEmployee?: boolean;
  employeeValues: any;
  PropertyEmployeeListApi?: any;
  serviceOption: any[];
}
interface serviceOptionsType {
  label: string;
  value: string;
}
const EmployeeModal = ({
  PropertyEmployeeListApi,
  title,
  show,
  handleClose,
  property_uuid,
  isEditEmployee,
  employeeValues,
  serviceOption,
}: AddEditModalProps & props) => {
  const [serviceOptions, setServiceOptions] = useState<serviceOptionsType[]>(
    []
  );
  const [isEndOfScroll, setIsEndOfScroll] = useState(false);
  const [limit, setLimit] = useState(10);
  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const handleModalClose = () => {
    handleClose();
    formik.resetForm();
    formik.setFieldValue("add_property_service_uuids", null);
  };

  // /**
  //  * initialise property-services api
  //  */
  // const [
  //   PropertyServiceApi,
  //   { response: PropertyServiceRes, error: PropertyServiceErr },
  // ] = useFetch("propertyServices/list", {
  //   method: "post",
  // });

  /**
   * initialise property-employees list api
   */
  const [
    PropertyEmployeeListApi2,
    { response: PropertyEmployeeListRes, error: PropertyEmployeeListErr },
  ] = useFetch("propertyemployees/list", {
    method: "post",
  });

  /**
   * initialise property-EMPLOYEE add api
   */
  const [
    PropertyEmployeeAddApi,
    {
      response: PropertyEmployeeRes,
      error: PropertyEmployeeError,
      loading: PropertyEmployeeLoading,
    },
  ] = useFetch("propertyemployees/add", {
    method: "post",
  });

  /**
   * initialise property-EMPLOYEE add api
   */
  const [
    PropertyEmployeeEditApi,
    {
      response: editEmployeeRes,
      error: editEmployeeError,
      loading: editEmployeeLoading,
    },
  ] = useFetch(`propertyemployees/update/${employeeValues?.uuid}`, {
    method: "put",
  });

  const formik = useFormik({
    initialValues: propertyEmployees,
    validationSchema: propertyEmployeeSchema,
    onSubmit(values: any) {
      const isString = values?.email === "";
      try {
        isEditEmployee
          ? PropertyEmployeeEditApi({
              ...values,
              add_property_service_uuids: values.add_property_service_uuids
                ?.value
                ? [values.add_property_service_uuids.value]
                : [],
              email: isString ? null : values?.email,
            })
          : PropertyEmployeeAddApi({
              ...values,
              add_property_service_uuids: values.add_property_service_uuids
                ?.value
                ? [values.add_property_service_uuids.value]
                : [],
              email: isString ? null : values?.email,
              property_uuid,
            });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "PropertyEmployeeEditApi || PropertyEmployeeAddApi",
        });
      }
    },
  });

  // /** Call @PropertyServiceApi api */
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     PropertyServiceApi({
  //       start: 0,
  //       limit: -1,
  //       property_uuid,
  //     });
  //   }, 500);
  //   return () => clearTimeout(timer);
  // }, []);

  /** Call @PropertyEmployeeListApi api */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        PropertyEmployeeListApi({
          property_uuid,
          start: 0,
          limit: -1,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "PropertyEmployeeListApi",
        });
      }
      try {
        PropertyEmployeeListApi2({
          property_uuid,
          start: 0,
          limit,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "PropertyEmployeeListApi2",
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  /** Call @PropertyEmployeeListApi api */
  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        PropertyEmployeeListApi2({
          property_uuid,
          start: 0,
          limit,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "PropertyEmployeeListApi2",
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [limit]);

  /** Handle @PropertyServiceApi response */
  useEffect(() => {
    if (serviceOption) {
      const updatedList = serviceOption.map((item: any) => ({
        value: item?.uuid,
        label: item?.service_details?.name,
      }));
      setServiceOptions(updatedList);
    }
  }, [serviceOption]);

  /** Handle @PropertyEmployeeAddApi response */
  useEffect(() => {
    formik.setFieldValue("add_property_service_uuids", null);
    if (PropertyEmployeeRes) {
      showToast(PropertyEmployeeRes.message as string, "success");
      formik.resetForm();
      formik.setFieldValue("phone_number", "");
      formik.setFieldValue("email", "");
      try {
        PropertyEmployeeListApi({
          property_uuid,
          start: 0,
          limit: -1,
        });
      } catch (e: any) {
        notifyBugsnagError(e, { api: "PropertyEmployeeListApi" });
      }
      try {
        PropertyEmployeeListApi2({
          property_uuid,
          start: 0,
          limit,
        });
      } catch (e: any) {
        notifyBugsnagError(e, { api: "PropertyEmployeeListApi2" });
      }
    }
    if (PropertyEmployeeError) {
      showToast(PropertyEmployeeError.message as string, "error");
    }
  }, [PropertyEmployeeRes, PropertyEmployeeError]);

  /** Handle @editEmployeeRes  */
  useEffect(() => {
    if (editEmployeeRes) {
      showToast(editEmployeeRes.message, "success");
      formik.resetForm();
      formik.setFieldValue("add_property_service_uuids", null);
      handleModalClose();
      formik.setFieldValue("phone_number", "");
      formik.setFieldValue("email", "");
      try {
        PropertyEmployeeListApi({
          property_uuid,
          start: 0,
          limit: -1,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "PropertyEmployeeListApi",
        });
      }
    }
    if (editEmployeeError) {
      showToast(editEmployeeError.message as string, "error");
    }
  }, [editEmployeeRes, editEmployeeError]);

  useEffect(() => {
    if (isEditEmployee && employeeValues) {
      formik.setFieldValue("name", employeeValues?.name);
      formik.setFieldValue("email", employeeValues?.email);
      formik.setFieldValue(
        "add_property_service_uuids",
        employeeValues?.property_employees_services_details[0]
          ?.property_service_uuid
          ? {
              label:
                employeeValues?.property_employees_services_details[0]
                  ?.property_service_name,
              value:
                employeeValues?.property_employees_services_details[0]
                  ?.property_service_uuid,
            }
          : ""
      );
      formik.setFieldValue("phone_number", employeeValues?.phone_number);
    }
  }, [employeeValues]);

  useEffect(() => {
    const handleScroll = () => {
      const div = scrollableDivRef.current;
      if (div && div.scrollTop + div.clientHeight >= div.scrollHeight) {
        setIsEndOfScroll(true);
      } else {
        setIsEndOfScroll(false);
      }
    };

    // Add scroll event listener when component mounts
    const div = scrollableDivRef.current;
    if (div) {
      div.addEventListener("scroll", handleScroll);

      // Remove scroll event listener when component unmounts
      return () => {
        div.removeEventListener("scroll", handleScroll);
      };
    }
  });

  useEffect(() => {
    if (isEndOfScroll) {
      setLimit((limit) => limit + 10);
    }
  }, [isEndOfScroll]);
  return (
    <ModalLayout
      title={title}
      show={show}
      size={850}
      handleToggle={handleModalClose}
    >
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Container>
          <Row className="g-3">
            <Col md={6}>
              <InputField
                formik={formik}
                name="name"
                label="Employee Name"
                placeholder="Enter Employee Name"
              />
            </Col>
            <Col md={6}>
              <ReactSelectField
                name="add_property_service_uuids"
                options={serviceOptions}
                label="Service (Optional)"
                placeholder="Select Service"
                className="form_select"
                formik={formik}
                value={formik.values?.add_property_service_uuids}
                formikErrorMesg={formik.errors?.add_property_service_uuids}
              />
            </Col>
            <Col md={6}>
              <InputField
                formik={formik}
                name="email"
                label="Email (Optional)"
                disabled={isEditEmployee && employeeValues?.email}
                style={{
                  cursor:
                    isEditEmployee && employeeValues?.email
                      ? "not-allowed"
                      : "",
                }}
                placeholder="Enter Email"
              />
            </Col>
            <Col md={6}>
              <PhoneIntputField
                label="Phone Number (Optional)"
                name="phone_number"
                formik={formik}
                phoneCodeId={(phoneId: string) =>
                  formik.setFieldValue("phone_code_id", phoneId)
                }
              />
            </Col>
            {!isEditEmployee && (
              <Col
                sm={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 20,
                }}
              >
                <ThemeButton
                  loading={PropertyEmployeeLoading || editEmployeeLoading}
                  onClick={() => formik?.handleSubmit()}
                >
                  Add Employee
                </ThemeButton>

                <ThemeButton variant="secondary" onClick={handleModalClose}>
                  Close
                </ThemeButton>
              </Col>
            )}
          </Row>
          {!isEditEmployee &&
            PropertyEmployeeListRes?.data?.list?.length > 0 && <hr />}
          {!isEditEmployee &&
            PropertyEmployeeListRes?.data?.list?.length > 0 && (
              <Row
                style={{
                  maxHeight: 400,
                  overflow: "auto",
                }}
                ref={scrollableDivRef}
              >
                <ListEmployess
                  propertyEmployeeListRes={PropertyEmployeeListRes?.data?.list}
                  propertyEmployeeListErr={PropertyEmployeeListErr}
                  PropertyEmployeeListApi={PropertyEmployeeListApi2}
                  property_uuid={property_uuid}
                  isModal
                  limit={limit}
                />
              </Row>
            )}
          <Row className="mt-4 d-flex justify-content-center">
            <div className="d-flex gap-3 py-4 justify-content-center">
              {isEditEmployee && (
                <ThemeButton
                  loading={editEmployeeLoading || PropertyEmployeeLoading}
                  type="submit"
                >
                  Save
                </ThemeButton>
              )}
            </div>
          </Row>
        </Container>
      </form>
    </ModalLayout>
  );
};

export default EmployeeModal;
