import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  changeOwner,
  copyIconNew,
  crossIcon,
  deleteIcon,
  editIcon,
  eye,
  login,
  qr,
  qrLock,
  unlink,
} from "../../../../assests/Icons/icon";
import copyUrl from "../../../../assests/copyss.svg";
import dollarIcon from "../../../../assests/svg/dollarIcon.svg";
import searchIcon from "../../../../assests/svg/searchIcon.svg";
import {
  copyToClipBoard,
  notifyBugsnagError,
  openQrCode,
  showToast,
} from "../../../../common/utils/Functions";
import transformDataToTableFormat from "../../../../common/utils/convertOrgData";
import ChangeOwnerConfirmationModal from "../../../../components/changeOwnerConfirmationModal/changeOwnerConfirmationModal";
import DashboardLayout from "../../../../components/layouts/DashboardLayout";
import ConfirmationModal from "../../../../components/modals/ConfirmationModal/ConfirmationModal";
import InactiveActiveModal from "../../../../components/modals/InactiveActiveModal/InactiveActiveModal";
import AddEditOrgnaizationModal from "../../../../components/modals/addEditOrgnaization/addEditOrgnaizationModal";
import QrCodelist from "../../../../components/modals/addEditOrgnaization/qrCodelist";
import ChangeOwnerModal from "../../../../components/modals/changeOwnerModal/changeOwnerModal";
import NoDataFound from "../../../../components/noDataFound";
import ReactExcel from "../../../../components/reactExcel/ReactExcel";
import ExcelForOrgDetails from "../../../../components/reactExcel/ReactXlsx";
import ReactLoader from "../../../../components/reactLoader/ReactLoader";
import ReactTable from "../../../../components/reactTable/ReactTable";
import InputField from "../../../../components/theme/InputField";
import ThemeButton from "../../../../components/theme/ThemeButton";
import useFetch from "../../../../hooks/useFetch";
import { RootState } from "../../../../redux/store";
import { propertyType } from "../../../../types/manageProperty";
import {
  organizationAddEditType,
  organizationDetailType,
} from "../../../../types/organization";
import { addOrganizationSchema } from "../../../../validations/OrganizationSchema";
import styles from "../organizations/organizations.module.scss";

const initialValuesData = {
  organization_name: "",
  organization_identifier: "",
  first_name: "",
  last_name: "",
  email: "",
  phone_code_id: 1,
  phone_number: "",
  partner: null,
  apiKey: "",
  theme_color: "#ffffff",
  font_color: "#000000",
  top_tipper_fees: null,
  logo_data: null,
};
export interface qrcodeType {
  name: string;
  qr_code: string;
  copy_link: string;
}

const Details = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useSelector((store: RootState) => store?.userData);
  const [organizationData, setOrganizationData] =
    useState<organizationDetailType>();
  const [initialValues, setInitialValues] =
    useState<organizationAddEditType>(initialValuesData);
  const [orgUuid, setOrgUuid] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [validationSchema, setValidationSchema] = useState<any>();
  const [organizationExcelData, setOrganizationExcelData] = useState<any[]>([]);
  const [orgDetail, setOrgDetail] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [propertyList, setpropertyList] = useState<propertyType[]>([]);
  const [propertyExcelList, setpropertyExcelList] = useState<propertyType[]>(
    []
  );
  const [showChangeOwner, setShowChangeOwner] = useState<boolean>(false);
  const [confirmChangeOwner, setConfirmChangeOwner] = useState<boolean>(false);

  const [show, setShow] = useState(false);
  const [showQr, setShowQr] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [organizationDetailsData, setOrganizationDetailsData] = useState<
    {
      heading: string;
      name: string;
      icon?: any;
      style?: string;
      copyIcon?: any;
    }[]
  >();
  const [openInActive, setOpenInActive] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const handleInActive = () => setOpenInActive(false);
  const handleDelete = () => setOpenDelete(false);
  const [propertyQrCodeList, setPropertyQrCodeList] = useState<qrcodeType[]>(
    []
  );
  const [openUnlinkModal, setOpenUnlinkModal] = useState(false);

  const handleUnlink = () => setOpenUnlinkModal(false);

  /**
   * @Pagination states
   * 1. pageLimit    :- store limit of data that will render on current page
   * 2. totalRecords :- store total number of data in partners api
   * 3. currentPage  :- store current page number
   */
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  // Function to handle closing the add/edit modal
  const handleClose = () => {
    setShowChangeOwner(false);
    setConfirmChangeOwner(false);
  };
  const handleCloseQr = () => setShowQr(false);
  const toggleAddEdit = () => setShow(!show);

  /** Api definition */
  const [
    viewOrganizationApi,
    { response: viewResponse, loading: viewLoading, error: viewError },
  ] = useFetch(`/organization/view/${uuid}`);

  const [
    updateOrganizationApi,
    { response: updateResponse, loading: updateLoading, error: updateError },
  ] = useFetch(`/organization/update/${uuid}`, {
    method: "put",
  });
  const [
    statusApi,
    { response: statusResponse, loading: statusLoading, error: statusError },
  ] = useFetch(`/organization/active-inactive/${uuid}`, {
    method: "post",
  });
  const [
    deleteApi,
    { response: deleteResponse, loading: deleteLoading, error: deleteError },
  ] = useFetch(`/organization/delete/${uuid}`, {
    method: "delete",
  });

  /** Api definition */
  const [
    loginAsAdminApi,
    {
      response: loginAsAdminRes,
      loading: loginAsAdminLoading,
      error: loginAsAdminErr,
    },
  ] = useFetch(`/auth/admin-org-login?uuid=${uuid}`);

  /** Api definition */
  const [forgotPasswordApi, { response, loading, error }] = useFetch(
    "/organization/reset-password",
    {
      method: "post",
    }
  );

  // const excelColumns = [
  //   {
  //     header: "Name",
  //     key: "name",
  //     width: 20,
  //   },
  //   {
  //     header: "Status",
  //     key: "status",
  //     width: 20,
  //   },
  //   {
  //     header: "Url ",
  //     key: "url",
  //     width: 20,
  //   },
  //   {
  //     header: "Admin Name",
  //     key: "admin_name",
  //     width: 20,
  //   },
  //   {
  //     header: "Admin Email",
  //     key: "admin_email",
  //     width: 20,
  //   },
  //   {
  //     header: "Admin No",
  //     key: "admin_no",
  //     width: 20,
  //   },
  //   {
  //     header: "Top Tipper Fee",
  //     key: "fee",
  //     width: 20,
  //   },
  //   {
  //     header: "Theme Color",
  //     key: "theme_color",
  //     width: 20,
  //   },
  //   {
  //     header: "Font Color",
  //     key: "font_color",
  //     width: 20,
  //   },
  // ];

  /** handle to update org-excel data  */
  const handleOrgExcelData = () => {
    orgDetailsExcelApi({
      uuid,
    });
  };
  const [
    propertyListApi,
    {
      response: propertyResponse,
      loading: propertyLoading,
      error: propertyError,
    },
  ] = useFetch(`/property/list`, { method: "post" });

  /** Api definition */
  const [
    propertyExcelListApi,
    {
      response: propertyExcelListResponse,
      loading: propertyExcelListLoading,
      error: propertyExcelListError,
    },
  ] = useFetch(`/property/list`, {
    method: "post",
  });

  /** Api definition */
  const [
    orgDetailsExcelListApi,
    {
      response: orgDetailsExcelListResponse,
      loading: orgDetailsExcelListLoading,
      error: orgDetailsExcelListError,
    },
  ] = useFetch(`/common/get-qr-code-details`, {
    method: "post",
  });

  const [
    orgDetailsExcelApi,
    {
      response: orgDetailsExcelResponse,
      loading: orgDetailsExcelLoading,
      error: orgDetailsExcelError,
    },
  ] = useFetch(`/organization/export-org-details/${uuid}`);

  const [
    organizationUnLinkStripeApi,
    { response: unlinkResponse, loading: unlinkLoading, error: unlinkError },
  ] = useFetch(`/organization/unlink-stripe-account/${uuid}`);

  /** Handle response */
  useEffect(() => {
    if (statusResponse) {
      showToast(statusResponse.message as string, "success");
      handleInActive();
      viewOrganizationApi();
    }
    if (statusError) {
      showToast(statusError.message as string, "error");
    }
  }, [statusResponse, statusError]);

  useEffect(() => {
    if (deleteResponse) {
      showToast(deleteResponse.message as string, "success");
      handleDelete();
      navigate("/organizations");
    }
    if (deleteError) {
      showToast(deleteError.message as string, "error");
    }
  }, [deleteResponse, deleteError]);
  useEffect(() => {
    if (viewResponse) {
      setOrganizationData(viewResponse.data);
      if (viewResponse.data) {
        setOrganizationDetailsData([
          // {
          //   heading: "Organization ID",
          //   name: viewResponse.data.id,
          // },
          {
            heading: "URL Abbreviation",
            name: viewResponse.data.url_code,
            copyIcon: copyUrl,
          },
          {
            heading: "Admin Name",
            name: `${viewResponse.data.user_details.first_name} ${
              viewResponse.data.user_details.last_name
                ? viewResponse.data.user_details.last_name
                : ""
            }`,
          },
          {
            heading: "Admin Email",
            name: viewResponse.data.user_details.email,
            style: "3 /span 2",
          },
          {
            heading: "Admin Phone",
            name:
              viewResponse.data?.user_details?.phone_number &&
              viewResponse.data?.user_details?.phone_number != "null"
                ? `${viewResponse.data.user_details.phone_code_details.phone_code} ${viewResponse.data.user_details.phone_number}`
                : "--",
          },
          {
            heading: "TopTipper Fee",
            name: viewResponse.data.top_tipper_fees?.toFixed(2),
            icon: dollarIcon,
          },
          {
            heading: "Color Theme",
            name: viewResponse.data.theme_color,
          },
          {
            heading: "Font Color",
            name: viewResponse.data.font_color,
          },
          {
            heading: "Partner",
            name: viewResponse.data?.partner_details?.name || "--",
          },
          {
            heading: "API Key",
            name: viewResponse.data.api_key?.trim() || "--",
          },
        ]);
      }
    }
    if (viewError) {
      if (viewError.message) {
        showToast(viewError.message as string, "error");
      }
    }
    if (viewError?.status === 3) {
      navigate(-1);
    }
  }, [viewResponse, viewError]);

  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        propertyListApi({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          search: searchValue,
          organization_uuid: uuid,
        });
      } catch (e: any) {
        notifyBugsnagError(e, {
          api: "propertyListApi",
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [currentPage, pageLimit, searchValue]);

  useEffect(() => {
    if (propertyResponse) {
      setTotalRecords(propertyResponse.data?.totalRecords);
      const updatedList = propertyResponse.data?.list?.map((item: any) => ({
        id: item?.id,
        name: item?.name,
        address: `${item.address ? item.address + ", " : ""} ${
          item.city ? item.city + ", " : ""
        } ${
          item.property_state_details?.name
            ? item.property_state_details?.name + ", "
            : ""
        } ${item.zip_code ? item.zip_code + ", " : ""} United States`,
        uuid: item?.uuid,
        is_active: item?.is_active,
        qr_code: item?.qr_code,
        copy_link: item?.copy_link,
      }));
      const qrCodeList = propertyResponse.data?.list?.map(
        (item1: qrcodeType) => ({
          name: item1?.name,
          qr_code: item1?.qr_code,
          copy_link: item1?.copy_link,
        })
      );
      setpropertyList(updatedList);
      setPropertyQrCodeList(qrCodeList);
    }
    if (propertyError) {
      showToast(propertyError.message as string, "error");
    }
  }, [propertyResponse, propertyError]);

  /** useEffect for unlink stripe account api */
  useEffect(() => {
    if (unlinkResponse) {
      viewOrganizationApi();
      showToast(unlinkResponse.message as string, "success");
    }
    if (unlinkError) {
      showToast(unlinkError.message as string, "error");
    }
    handleUnlink();
  }, [unlinkResponse, unlinkError]);

  /** Calling api */
  useEffect(() => {
    if (uuid) {
      viewOrganizationApi();
    }
  }, [uuid]);

  useEffect(() => {
    if (updateResponse) {
      viewOrganizationApi();
    }
  }, [updateResponse]);
  const formatAddress = (addressDetails: any) => {
    if (
      !addressDetails?.address &&
      !addressDetails?.city &&
      !addressDetails?.property_state_details?.name &&
      !addressDetails?.zip_code
    ) {
      return ""; // Return empty string if all fields are empty
    }

    return `${addressDetails?.address ? addressDetails?.address + ", " : ""}${
      addressDetails?.city ? addressDetails?.city + ", " : ""
    }${
      addressDetails?.property_state_details?.name
        ? addressDetails?.property_state_details?.name + ", "
        : ""
    }${addressDetails?.zip_code ? addressDetails?.zip_code + ", " : ""} `;
  };

  useEffect(() => {
    if (orgDetailsExcelResponse) {
      const orgDataRes = orgDetailsExcelResponse?.data?.org;
      const orgExcelRes = [
        {
          name: orgDataRes?.organization_details?.name
            ? orgDataRes?.organization_details?.name
            : "--",
          // id: orgDataRes?.organization_details?.id.toString()
          //   ? orgDataRes?.organization_details?.id.toString()
          //   : "--",
          status: orgDataRes?.is_active === 1 ? "Active" : " In Active",
          admin_name: orgDataRes?.first_name
            ? orgDataRes?.first_name + orgDataRes?.last_name
            : "--",
          admin_email: orgDataRes?.email ? orgDataRes?.email : "--",
          admin_no: orgDataRes?.phone_number
            ? `+${orgDataRes?.phone_code_id} ${orgDataRes?.phone_number}`
            : "--",
          url: orgDataRes?.organization_details?.url_code
            ? orgDataRes?.organization_details?.url_code
            : "--",
          fee:
            "$" + orgDataRes?.organization_details?.top_tipper_fees?.toFixed(2),
          theme_color: orgDataRes?.organization_details?.theme_color
            ? orgDataRes?.organization_details?.theme_color
            : "--",
          font_color: orgDataRes?.organization_details?.font_color
            ? orgDataRes?.organization_details?.font_color
            : "--",
          partner_name: orgDataRes?.organization_details?.partner_details?.name
            ? orgDataRes?.organization_details?.partner_details?.name
            : "--",
          api_key: orgDataRes?.organization_details?.api_key
            ? orgDataRes?.organization_details?.api_key
            : "--",
          qr_code: orgDataRes?.organization_details?.qr_code
            ? orgDataRes?.organization_details?.qr_code
            : "--",
          link: orgDataRes?.organization_details?.copy_link
            ? orgDataRes?.organization_details?.copy_link
            : "--",
          stripe_id: orgDataRes?.organization_details?.stripe_connect_account_id
            ? orgDataRes?.organization_details?.stripe_connect_account_id
            : "--",
        },
      ];
      setOrgDetail(orgExcelRes);
      const adrs = {
        address: "252",
        city: "wrwq",
        property_state_details: {
          name: "wrwq",
        },
        zip_code: "wqtwq",
      };
      const result = [
        {
          properties:
            orgDetailsExcelResponse?.data?.org?.organization_details?.properties_details?.map(
              (property: any) => [
                property?.id.toString() ? property?.id.toString() : "--",
                property?.name ? property?.name : "--",
                property?.phone_number ? property?.phone_number : "--",
                property?.copy_link ? property?.copy_link : "--",
                property?.qr_code ? property?.qr_code : "--",
                formatAddress({
                  address: property?.address,
                  city: property?.city,
                  property_state_details: property?.property_state_details,
                  zip_code: property?.zip_code,
                }) + " United States",
                property?.is_active ? "Active" : "In Active",
              ]
            ),
        },
        {
          services_rooms: [
            ...orgDetailsExcelResponse?.data?.services?.map((service: any) => [
              service.property_detail?.name
                ? service.property_detail?.name
                : "--",
              "Service",
              service.id?.toString() ? service.id?.toString() : "--",
              service?.services_details?.name
                ? service?.services_details?.name
                : "--",
              service?.copy_link ? service?.copy_link : "--",
              service?.qr_code ? service?.qr_code : "--",
            ]),
            ...orgDetailsExcelResponse?.data?.rooms?.map((room: any) => [
              room.property_detail?.name ? room.property_detail?.name : "--",
              "Room",
              room.id?.toString() ? room.id?.toString() : "--",
              room.name ? room.name : "--",
              room?.copy_link ? room?.copy_link : "--",
              room?.qr_code ? room?.qr_code : "--",
            ]),
          ],
        },

        {
          employees: orgDetailsExcelResponse?.data?.employees?.map(
            (employee: any) => [
              employee.property_detail?.name
                ? employee.property_detail?.name
                : "--",
              employee?.id.toString() ? employee?.id.toString() : "--",
              employee?.name ? employee?.name : "--",
              employee?.phone_number ? employee?.phone_number : "--",
              employee?.email ? employee?.email : "--",
              employee?.copy_link ? employee?.copy_link : "--",
              employee?.qr_code ? employee?.qr_code : "--",
            ]
          ),
        },
      ];

      setOrganizationExcelData([...result]);
    }
  }, [orgDetailsExcelResponse, orgDetailsExcelError]);

  const DropdownItems = () => {
    return (
      <div
        style={{
          border: "1px solid #A9B0BF",
          paddingLeft: "11px",
          /* height: 44px; */
          width: "34px",
          borderRadius: "4px",
          backgroundColor: "#ededed",
        }}
      >
        {" "}
        <Dropdown
          align="end"
          // onClick={(e) => e.stopPropagation()}
          className={styles.dropDots}
        >
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            ...
          </Dropdown.Toggle>

          <Dropdown.Menu
            style={{
              maxWidth: "max-content",
              minWidth: "150px",
              zIndex: "99",
            }}
          >
            <Dropdown.Item
              onClick={() => {
                setCurrentStep(1);
                toggleAddEdit();
                setEditModal(true);
                viewOrganizationApi();
                setInitialValues(initialValues);
              }}
            >
              {" "}
              {editIcon}
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setOpenInActive(true);
              }}
            >
              {" "}
              {crossIcon}
              {viewResponse?.data?.user_details?.is_active
                ? "Inactivate"
                : "Activate"}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setOpenDelete(true);
              }}
            >
              {" "}
              {deleteIcon}
              Delete
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setConfirmChangeOwner(true);
              }}
            >
              {" "}
              {changeOwner}
              Change Owner
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };
  const formik = useFormik<organizationAddEditType>({
    initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      formik.setFieldTouched("top_tipper_fees", true, false);
      if (currentStep === 1) {
        setCurrentStep(2);
        formik.setFieldTouched("apiKey", false, false);
      } else if (currentStep === 2) {
        setCurrentStep(3);
        formik.setFieldTouched("theme_color", false, false);
        formik.setFieldTouched("font_color", false, false);
        // formik.setFieldTouched("top_tipper_fees", false, false);
      } else {
        const apiData = {
          organization_name: values.organization_name,
          organization_identifier: editModal
            ? undefined
            : values.organization_identifier,
          first_name: values.first_name,
          last_name: values.last_name,
          email: editModal ? undefined : values.email,
          phone_code_id: 1,
          phone_number: `${values.phone_number}`,
          partner_id: values.partner?.value || null,
          api_key: values?.apiKey || "",
          theme_color: values.theme_color,
          font_color: values.font_color,
          top_tipper_fees: values.top_tipper_fees,
          media_id: values?.logo_data?.id || null,
          address: null,
          city: null,
          state: null,
          zip_code: null,
        };
        if (editModal) {
          try {
            await updateOrganizationApi(apiData);
            setCurrentStep(1);
            setShow(false);
            formik.resetForm();
            // setUuid("");
          } catch (e: any) {
            notifyBugsnagError(e, {
              api: "updateOrganizationApi",
            });
          }
        }
        //  else {
        //   await addOrganizationApi(apiData);
        //   setCurrentStep(1);
        //   formik.resetForm();
        //   setUuid("");
        // }
      }
    },
  });
  useEffect(() => {
    if (viewResponse) {
      const data = viewResponse.data;

      setInitialValues({
        organization_name: data?.name,
        organization_identifier: data?.url_code,
        first_name: data?.user_details?.first_name,
        last_name: data?.user_details?.last_name,
        email: data?.user_details?.email,
        phone_code_id: data?.user_details.phone_code_details?.id,
        phone_number:
          data.user_details.phone_number != "null"
            ? data?.user_details?.phone_number
            : "",
        partner: data?.partner_details?.id
          ? {
              value: data?.partner_details?.id,
              label: data?.partner_details?.name,
            }
          : null,
        apiKey: data?.api_key?.trim(),
        theme_color: data?.theme_color,
        font_color: data?.font_color,
        top_tipper_fees: Number(data?.top_tipper_fees)?.toFixed(2),
        logo_data: {
          id: data?.organization_media_details?.id || null,
          name: data?.organization_media_details?.name || "",
          url: data?.organization_media_details?.url || "",
        },
      });
    }
    if (viewError) {
      if (viewError.message) {
        showToast(viewError.message as string, "error");
      }
    }
    if (viewError?.status === 3) {
      navigate(-1);
    }
  }, [viewResponse, viewError, editModal]);
  useEffect(() => {
    const validation = addOrganizationSchema(currentStep);
    setValidationSchema(validation);
  }, [currentStep]);
  useEffect(() => {
    if (loginAsAdminRes) {
      // Open a new tab with the encoded state data
      if (process.env.NODE_ENV === "development") {
        window.open(
          `/redirect-as-admin-page/${loginAsAdminRes?.data?.token}`,
          "_blank"
        );
      } else {
        window.open(
          `${process.env.REACT_APP_BASE_URL_ORG}/redirect-as-admin-page/${loginAsAdminRes?.data?.token}`,
          "_blank"
        );
      }
    }
    if (loginAsAdminErr) {
      showToast(loginAsAdminErr.message as string, "error");
    }
  }, [loginAsAdminRes, loginAsAdminErr]);

  /** Handle api response */
  useEffect(() => {
    if (response) {
      showToast(response.message as string, "success");
    }
    if (error) {
      showToast(error.message as string, "error");
    }
  }, [response, error]);

  useEffect(() => {
    if (updateError) {
      showToast(updateError?.message as string, "error");
    }
  }, [updateError]);
  const headings = [
    {
      key: "id",
      label: "Property ID",
      onClick: (item: any) => {
        navigate(`/organizations/view-property/${item?.uuid}`);
      },
    },
    {
      key: "name",
      label: "Property Name",
      onClick: (item: any) => {
        navigate(`/organizations/view-property/${item?.uuid}`);
      },
    },
    {
      key: "address",
      label: "Property Address",
    },
  ];
  const propertyExcelheadings = [
    { key: "id", header: "Property ID" },
    { key: "name", header: "Property Name", width: 20 },
    { key: "is_active", header: "Status", width: 20 },
    {
      key: "address",
      header: "Property Address",
      width: 50,
    },
    { key: "qr_code", header: "Qr Code", width: 85 },
    { key: "copy_link", header: "Link", width: 85 },
  ];
  const actionButtonOptions = [
    {
      name: "View Property Info",
      icon: eye,
      onClick: (item: any) => {
        navigate(`/organizations/view-property/${item?.uuid}`);
      },
    },
    {
      name: " View Qr Code",
      icon: eye,
      onClick: (item: propertyType) => {
        openQrCode(item?.qr_code as string);
      },
    },
    {
      name: " Copy Tip URL",
      icon: copyIconNew,
      onClick: (item: propertyType) => {
        copyToClipBoard(item?.copy_link as string);
      },
    },
  ];

  const handleExcelApi = () => {
    try {
      propertyExcelListApi({
        start: (currentPage - 1) * pageLimit,
        limit: -1,
        search: searchValue,
        organization_uuid: uuid,
      });
    } catch (e: any) {
      notifyBugsnagError(propertyExcelListError, {
        api: "propertyExcelListApi",
      });
    }
  };
  /** Handle response */
  useEffect(() => {
    if (propertyExcelListResponse) {
      const updateOrgExcel = propertyExcelListResponse.data.list?.map(
        (item: propertyType) => {
          return {
            id: item?.id,
            name: item?.name,
            address: `${item.address ? item.address + ", " : ""} ${
              item.city ? item.city + ", " : ""
            } ${
              item.property_state_details?.name
                ? item.property_state_details?.name + ", "
                : ""
            } ${item.zip_code ? item.zip_code + ", " : ""} United States`,
            uuid: item?.uuid,
            is_active: item?.is_active ? "Active" : "In Active",
            qr_code: item?.qr_code,
            copy_link: item?.copy_link,
          };
        }
      );
      setpropertyExcelList([...updateOrgExcel]);
    }
    if (propertyExcelListError) {
      showToast(propertyExcelListError.message as string, "error");
    }
  }, [propertyExcelListResponse, propertyExcelListError]);
  const columnWidths = [
    30, 30, 50, 85, 85, 85, 85, 85, 85, 85, 85, 85, 85, 85, 85,
  ];
  // useEffect(() => {
  //   setOrgUuid(uuid);
  // }, []);

  return (
    <DashboardLayout>
      {viewError ? (
        <NoDataFound viewHeight={"calc(100vh - 200px)"} />
      ) : viewLoading ? (
        <ReactLoader viewHeight={"calc(100vh - 200px)"} />
      ) : (
        <div className={styles.dash_page}>
          <Container>
            <Row className="g-xl-4 g-3">
              <Col xxl={12} xl={12}>
                <div
                  className={`${styles.dashboardHeading} ${styles.orgnaizationDetails}`}
                >
                  <div className={styles.alltips}>
                    <h2
                      className="d-flex gap-2"
                      style={{ lineHeight: "normal" }}
                    >
                      Organization Details <DropdownItems />
                    </h2>
                    <p>Please see below the brief detail of organization.</p>
                  </div>
                  <div className={styles.search}>
                    <button
                      className="btn btn-secondary commonBtnTransparnt colorBg mt-0 ms-2"
                      onClick={() => {
                        openQrCode(viewResponse?.data?.qr_code);
                      }}
                    >
                      <span>{qr}</span>
                      QR Card
                    </button>
                    <ThemeButton
                      onClick={(e) => {
                        forgotPasswordApi({
                          email: viewResponse.data.user_details.email,
                        });
                      }}
                      className="btn btn-secondary commonBtnTransparnt colorBg mt-0 ms-2"
                    >
                      <span>{qrLock}</span>
                      Reset Password
                    </ThemeButton>
                    <button
                      className="btn btn-secondary commonBtnTransparnt colorBg mt-0 ms-2"
                      onClick={() => {
                        setOpenUnlinkModal(true);
                      }}
                    >
                      <span>{unlink}</span>
                      Unlink Stripe
                    </button>
                    <ThemeButton
                      className="btn btn-secondary commonBtnTransparnt colorBg mt-0 ms-2"
                      onClick={() => {
                        loginAsAdminApi();
                      }}
                      loading={loginAsAdminLoading}
                    >
                      <span>{login}</span>
                      Login As Admin
                    </ThemeButton>
                    {/* <ReactExcel
                      buttonTitle="Export Organization Details"
                      coulmns={excelColumns}
                      excelData={organizationExcelData}
                      fileName={organizationData?.name}
                      fileType="xlsx"
                      loading={orgExcelLoader}
                      onClick={handleOrgExcelData}
                      setExcelData={setOrganizationExcelData}
                    /> */}
                    <ExcelForOrgDetails
                      orgDetails={orgDetail}
                      setOrgDetail={setOrgDetail}
                      setExcelData={setOrganizationExcelData}
                      columnWidths={columnWidths}
                      tables={transformDataToTableFormat(organizationExcelData)}
                      buttonTitle="Export Organization Details"
                      loading={orgDetailsExcelLoading}
                      onClick={handleOrgExcelData}
                    />
                    {/* <ThemeButton> Export Organization Details</ThemeButton> */}
                  </div>
                </div>
              </Col>

              <Col xxl={12} xl={12}>
                <div className={styles.dashboardTable}>
                  <Row>
                    <Col sm={12} md={3}>
                      <div className={styles.LogoName}>
                        <div
                          className={styles.LogoBg}
                          style={{
                            backgroundColor: organizationData?.theme_color,
                          }}
                        >
                          {organizationData?.organization_media_details?.url ? (
                            <img
                              src={
                                organizationData?.organization_media_details
                                  ?.url
                              }
                              alt="logos"
                            />
                          ) : (
                            "No Image"
                          )}
                        </div>
                        <div className={styles.NameText}>
                          <h6>S</h6>
                          <h5
                            className={
                              organizationData?.stripe_status === 1
                                ? ""
                                : organizationData?.stripe_status === 2
                                ? ""
                                : organizationData?.stripe_status === 3
                                ? styles.error
                                : organizationData?.stripe_status === 4
                                ? styles.error
                                : organizationData?.stripe_status === 5
                                ? styles.error
                                : styles.error
                            }
                          >
                            <span />
                            {organizationData?.stripe_status === 1
                              ? "Complete"
                              : organizationData?.stripe_status === 2
                              ? "Enabled"
                              : organizationData?.stripe_status === 3
                              ? "Error"
                              : organizationData?.stripe_status === 4
                              ? "Pending"
                              : organizationData?.stripe_status === 5
                              ? "InProgress"
                              : "Not Setup"}
                          </h5>
                        </div>
                        <div className={styles.copyText}>
                          <span>
                            {organizationData?.stripe_connect_account_id
                              ? organizationData?.stripe_connect_account_id
                              : "--"}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col sm={12} md={9}>
                      <div className={styles.rightSides}>
                        <div className={styles.rightName}>
                          <h6>
                            Organization Name{" "}
                            <span
                              className={
                                organizationData?.user_details?.is_active
                                  ? styles.is_active
                                  : ""
                              }
                            />{" "}
                            {organizationData?.user_details?.is_active
                              ? "Active"
                              : "Inactive"}
                          </h6>
                          <h2
                            style={{
                              wordBreak: "break-word",
                            }}
                          >
                            {organizationData?.name}
                          </h2>
                        </div>
                        <div className={styles.tipButtons}>
                          <button>
                            Last Tip Transaction :{" "}
                            {organizationData?.transaction_details
                              ? moment(
                                  organizationData?.transaction_details
                                    ?.createdAt
                                ).format("MM/DD/YYYY")
                              : "--"}{" "}
                            {/* {moment().format("MM/DD/YYYY")} */}
                          </button>
                          <button>
                            Last Login :{" "}
                            {organizationData?.user_details.last_login
                              ? moment(
                                  organizationData?.user_details.last_login
                                ).format("MM/DD/YYYY")
                              : "--"}
                          </button>
                        </div>

                        <div className={styles.cards}>
                          {organizationDetailsData?.map((item: any) => (
                            <div
                              className={styles.cardsBox}
                              key={item.id}
                              style={{
                                gridColumn: item?.style ? item?.style : "",
                              }}
                            >
                              {item.heading !== "Color Theme" &&
                                item.heading !== "Font Color" && (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <h5>{item?.heading}</h5>
                                      {item.copyIcon && (
                                        <img
                                          // className={`field_icon`}
                                          src={item?.copyIcon}
                                          alt="email"
                                          onClick={() => {
                                            copyToClipBoard(
                                              organizationData?.copy_link as string
                                            );
                                          }}
                                          style={{
                                            padding: "4px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      )}
                                    </div>

                                    <h4
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {item.icon && (
                                        <img
                                          className={`field_icon`}
                                          src={item?.icon}
                                          alt="email"
                                          style={{
                                            padding: "4px",
                                          }}
                                        />
                                      )}
                                      {item?.name}
                                    </h4>
                                  </>
                                )}

                              {(item.heading === "Color Theme" ||
                                item.heading === "Font Color") && (
                                <div className={styles.colorTheme}>
                                  <div className={styles.colorLeft}>
                                    <h5>
                                      {item.heading === "Color Theme"
                                        ? "Color Theme"
                                        : "Font Color"}
                                    </h5>
                                    <h4>{item?.name}</h4>
                                  </div>
                                  <div className={styles.colorRight}>
                                    <span
                                      style={{
                                        backgroundColor: item?.name,
                                        border: "0.5px solid #a9b0bf",
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>

              <ReactTable
                data={propertyList || []}
                headings={headings}
                actionColumn
                statusColumn
                actionButtonOptions={actionButtonOptions}
                currentPage={currentPage}
                itemPerPage={pageLimit}
                totalItems={totalRecords}
                setPerPageLimit={setPageLimit}
                pageLimit={pageLimit}
                setCurrentPage={setCurrentPage}
                loading={propertyLoading || !propertyList}
                pagination={true}
              >
                <div className={styles.dashboardHeadings}>
                  <div className={`${styles.search} ${styles.searchQr}`}>
                    <div className={styles.searchField}>
                      <InputField
                        name="search_organization_name"
                        icon={searchIcon}
                        placeholder="Search by Property Name"
                        value={searchValue}
                        autoComplete="off"
                        onChange={(e) => {
                          setCurrentPage(1);
                          setSearchValue(e.target.value);
                        }}
                      />
                      {searchValue ? (
                        <span
                          className={styles.crossIcon}
                          onClick={() => setSearchValue("")}
                          style={{
                            position: "absolute",
                            top: "43%",
                            right: "10px",
                          }}
                        >
                          <RxCross1 />
                        </span>
                      ) : null}
                    </div>
                    <button
                      className="btn btn-secondary commonBtnTransparnt"
                      onClick={() => {
                        setShowQr(true);
                      }}
                    >
                      <span>{qr}</span>
                      QR Card
                    </button>
                    <ReactExcel
                      setExcelData={setpropertyExcelList}
                      loading={propertyExcelListLoading}
                      onClick={handleExcelApi}
                      buttonTitle="Export Properties"
                      fileType="xlsx"
                      fileName="Property List"
                      coulmns={propertyExcelheadings}
                      excelData={propertyExcelList}
                    />
                  </div>
                </div>
              </ReactTable>

              <QrCodelist
                show={showQr}
                handleClose={handleCloseQr}
                title="Property Level QR Codes"
                propertyQrCodeList={propertyQrCodeList}
              />

              <InactiveActiveModal
                handleToggle={handleInActive}
                title={`Are you sure you want to ${
                  viewResponse?.data?.user_details?.is_active
                    ? "deactivate"
                    : "activate"
                }
                    this organization ?`}
                show={openInActive}
                heading={
                  viewResponse?.data?.user_details?.is_active
                    ? "Deactivate"
                    : "Activate"
                }
                loading={statusLoading}
                confirm={() =>
                  statusApi({
                    is_active: viewResponse?.data?.user_details?.is_active
                      ? 0
                      : 1,
                  })
                }
              />
              <ConfirmationModal
                handleToggle={handleDelete}
                title="Are you sure you want to delete
                        this organization ?"
                show={openDelete}
                loading={deleteLoading}
                confirm={() => deleteApi()}
              />
              <AddEditOrgnaizationModal
                show={show}
                handleClose={() => {
                  toggleAddEdit();
                  // setUuid("");
                  formik.resetForm();
                }}
                editNameValue={organizationData?.name}
                title={editModal ? "Edit  Organization " : "Add Organization "}
                editModal={editModal}
                formik={formik}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                loading={updateLoading}
                partnerDetails={viewResponse?.data?.partner_details}
                // loading={loading || updateLoading}
                editLoading={viewLoading}
                setValidationSchema={setValidationSchema}
              />

              <ConfirmationModal
                handleToggle={handleUnlink}
                title="Are you sure you want to unlink stripe account ?"
                show={openUnlinkModal}
                loading={unlinkLoading}
                actionTitle="Unlink Stripe Account"
                confirm={() => organizationUnLinkStripeApi()}
              />

              <ChangeOwnerModal
                handleClose={handleClose}
                show={showChangeOwner}
                title={"Change Owner"}
                setOrgUuid={setOrgUuid}
              />

              <ChangeOwnerConfirmationModal
                show={confirmChangeOwner}
                handleToggle={handleClose}
                title="This action will deactivate the existing organization admin account."
                confirm={() => {
                  setShowChangeOwner(true);
                  setConfirmChangeOwner(false);
                }}
              />
            </Row>
          </Container>
        </div>
      )}
    </DashboardLayout>
  );
};
export default Details;
